<template>
  <node-view-wrapper class="splitText">
    <div class="splitText__row">
      <div class="splitText__column">
        <node-view-content class="splitText__cell"></node-view-content>
      </div>
      <div class="splitText__column">
        <node-view-content class="splitText__cell"></node-view-content>
      </div>
    </div>
  </node-view-wrapper>
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'
import { NodeViewContent, nodeViewProps, NodeViewWrapper } from '@tiptap/vue-2';

@Component({
  components: {NodeViewWrapper, NodeViewContent},
  props: { nodeViewProps }
})

export default class SplitTextComponent extends Vue {}
</script>

<style lang="scss">
.splitText {
  border: 1px dashed var(--gn-new-border);
  display: block;
  width: 100%;

  &__row {
    align-items: stretch;
    min-height: 20px;
    display: flex;
    height: auto;
    width: 100%;
  }

  &__column {
    border-right: 1px dashed var(--gn-new-border);
    vertical-align: middle;
    display: inline-block;
    min-height: 20px;
    width: 50%;

    &:last-of-type {
      border-right: none;
    }
  }
  
  &__cell {
    display: block;
    height: 100%;
    width: 100%;
  }
}
</style>