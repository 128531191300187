import { mergeAttributes, Node } from '@tiptap/core'

export const SplitTextColumn = Node.create({
  name: 'splitTextColumn',

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  group: 'block',
  content: 'block*',
  selectable: true,
  draggable: false,

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { 'data-type': this.name }), 0]
  },
})