// import { Node } from '@tiptap/core';
// import { mergeAttributes } from '@tiptap/vue-2';

// const CustomParagraphNode = Node.create({
//   name: 'customParagraphNode',
//   content: 'text*',
// });

// export default CustomParagraphNode;

import { mergeAttributes, Node } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-2";

import SplitTextComponent from './SplitTextComponent.vue';

export default Node.create({
  name: 'splitTextNode',
  group: 'block',
  inline: false,
  content: 'text*',
  draggable: false,
  parseHTML() {
    return [
      {
        tag: 'div[data-type="split-text-node"]'
      }
    ]
  },
  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { 'data-type': 'split-text-node' }), 0]
  },
  addNodeView() {
    return VueNodeViewRenderer(SplitTextComponent);
  }
})