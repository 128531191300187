import { mergeAttributes, Node } from '@tiptap/core'

export const SplitText = Node.create({
  name: 'splitText',

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  group: 'block',

  content: 'figcaption',

  draggable: true,

  isolating: true,

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { 'data-type': this.name }), 0]
  },
})