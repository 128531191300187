var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('node-view-wrapper', {
    staticClass: "splitText"
  }, [_c('div', {
    staticClass: "splitText__row"
  }, [_c('div', {
    staticClass: "splitText__column"
  }, [_c('node-view-content', {
    staticClass: "splitText__cell"
  })], 1), _c('div', {
    staticClass: "splitText__column"
  }, [_c('node-view-content', {
    staticClass: "splitText__cell"
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }